/**
 * Language codes for languages which
 * need to be considered right to left
 * ur - Urdu
 * ar - Arabic
 */
const RTL_LANGUAGE_CODES = ["ur", "ar"];

export const getLanguageDirection = (langCode: string) =>
    RTL_LANGUAGE_CODES.includes(langCode) ? "rtl" : "ltr";
