import { createContext, ReactNode, useContext, useState } from "react";
import {
    DEFAULT_RECEPTION_RECIPIENT,
    DEFAULT_RECIPIENT,
    Recipient,
} from "../settings";

export type QuestionWithAnswers = Record<string, PatientAnswers>;

export type PatientAnswers = {
    id: string;
    answers: string[];
};

export type Verification = {
    request: string | null;
    verify: string | null;
};
export type SymptomsTabType = "adults" | "children";

export type AnswerContextState = {
    selectedSectionId: string | null;
    setSelectedSectionId: (id: string) => void;
    selectedCategoryId: string | null;
    setSelectedCategoryId: (id: string) => void;
    selectedFlowId: string | null;
    setSelectedFlowId: (id: string) => void;
    selectedPageId: string | null;
    setSelectedPageId: (id: string) => void;
    selectedRecipient: Recipient;
    setSelectedRecipient: (Recipient: Recipient) => void;
    questionsWithAnswers: QuestionWithAnswers;
    setQuestionsWithAnswers: (questionsWithAnswer: QuestionWithAnswers) => void;
    verification: Verification;
    setVerification: (verification: Verification) => void;
    symptomsTab: SymptomsTabType;
    setSymptomsTab: (symptomsTab: SymptomsTabType) => void;
    resetAnswersState: () => void;
};

export const defaultState: AnswerContextState = {
    selectedSectionId: null,
    setSelectedSectionId: () => {},
    selectedCategoryId: null,
    setSelectedCategoryId: () => {},
    selectedFlowId: null,
    setSelectedFlowId: () => {},
    selectedPageId: null,
    setSelectedPageId: () => {},
    selectedRecipient: DEFAULT_RECIPIENT || DEFAULT_RECEPTION_RECIPIENT,
    setSelectedRecipient: () => {},
    questionsWithAnswers: {},
    setQuestionsWithAnswers: () => {},
    verification: {
        request: null,
        verify: null,
    },
    setVerification: () => {},
    setSymptomsTab: () => {},
    symptomsTab: "adults",
    resetAnswersState: () => {},
};

export const AnswerContext = createContext<AnswerContextState>(defaultState);

export const useAnswer = (): AnswerContextState => {
    const context = useContext(AnswerContext);

    // If we're on the client-side, ensure that anything trying to use the useAnswer hook is wrapped in the provider
    if (typeof window !== "undefined" && context === undefined) {
        throw new Error(
            "This component is a Answer context consumer, it must be used within a AnswerProvider",
        );
    }

    return context;
};

export const AnswerProvider = ({
    children,
    initialContextState = {},
}: {
    children: ReactNode;
    initialContextState?: Partial<AnswerContextState>;
}): JSX.Element => {
    const [selectedSectionId, setSelectedSectionId] = useState<string | null>(
        null,
    );
    const [selectedCategoryId, setSelectedCategoryId] = useState<string | null>(
        null,
    );
    const [selectedFlowId, setSelectedFlowId] = useState<string | null>(null);
    const [selectedPageId, setSelectedPageId] = useState<string | null>(null);
    const [selectedRecipient, setSelectedRecipient] =
        useState<Recipient>(DEFAULT_RECIPIENT);

    const [questionsWithAnswers, setQuestionsWithAnswers] =
        useState<QuestionWithAnswers>({});

    const [verification, setVerification] = useState<Verification>({
        request: null,
        verify: null,
    });

    const [symptomsTab, setSymptomsTab] = useState<SymptomsTabType>("adults");

    const resetAnswersState = () => {
        setSelectedCategoryId(null);
        setSelectedPageId(null);
        setSelectedFlowId(null);
        setSelectedSectionId(null);
        setQuestionsWithAnswers({});
        setVerification({
            verify: null,
            request: null,
        });
        setSelectedRecipient(DEFAULT_RECIPIENT);
        setSymptomsTab("adults");
    };

    return (
        <AnswerContext.Provider
            value={{
                selectedSectionId,
                setSelectedSectionId,
                selectedCategoryId,
                setSelectedCategoryId,
                selectedFlowId,
                setSelectedFlowId,
                selectedPageId,
                setSelectedPageId,
                selectedRecipient,
                setSelectedRecipient,
                questionsWithAnswers,
                setQuestionsWithAnswers,
                verification,
                setVerification,
                setSymptomsTab,
                symptomsTab,
                resetAnswersState,
                ...initialContextState,
            }}
        >
            {children}
        </AnswerContext.Provider>
    );
};
