import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useAnswer } from "../context/AnswerContext";
import { useContent } from "../context/ContentContext";
import { useOrganisation } from "../context/OrganisationContext";

const requestTypeToCategoryTitle = {
    admin: "I have an admin query",
    medical: "I want help for a medical issue",
} as const;

export const useNhsApp = (): boolean => {
    const { sections } = useContent();
    const { setSelectedSectionId, setSelectedCategoryId } = useAnswer();
    const { providedPatientDetails } = useOrganisation();

    const router = useRouter();
    const requestType = router.query?.requestType;

    const isValidRequestType = (
        requestType?: string | string[],
    ): requestType is keyof typeof requestTypeToCategoryTitle => {
        if (typeof requestType !== "string") return false;
        return Object.keys(requestTypeToCategoryTitle).includes(
            requestType.toLowerCase(),
        );
    };

    const selectedIds: { categoryId: string; sectionId: string } | null =
        useMemo(() => {
            if (!isValidRequestType(requestType) || !providedPatientDetails) {
                return null;
            }

            const category = sections
                .flatMap(({ categories }) => Object.values(categories ?? {}))
                .filter(({ isEnabled }) => isEnabled)
                .find(
                    ({ title }) =>
                        title === requestTypeToCategoryTitle[requestType],
                );
            const section =
                category &&
                sections.find(({ categories }) => categories[category.id]);

            if (!category || !section) {
                return null;
            }

            return { categoryId: category.id, sectionId: section.id };
        }, [sections, providedPatientDetails]);

    useEffect(() => {
        if (!selectedIds) return;
        setSelectedSectionId(selectedIds.sectionId);
        setSelectedCategoryId(selectedIds.categoryId);
    }, [selectedIds]);

    return selectedIds !== null;
};
