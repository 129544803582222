import { ReceptionFlowProvidedPatientDetails } from "../ReceptionDTOs";

export const createReceptionFlowResponse = (
    override: Partial<ReceptionFlowProvidedPatientDetails> = {},
): ReceptionFlowProvidedPatientDetails => {
    return {
        name: "",
        surname: "",
        dateOfBirth: "",
        phoneNumber: "",
        clinicianAppId: "",
        clinicianToken: "",
        organisationId: null,
        postCode: "",
        ...override,
    };
};

export const createMockProvidedReceptionPatientDetails = (
    override: Partial<ReceptionFlowProvidedPatientDetails> = {},
): ReceptionFlowProvidedPatientDetails => {
    return createReceptionFlowResponse({
        phoneNumber: "07777777777",
        clinicianAppId: "",
        clinicianToken: "",
        organisationId: null,
        postCode: "e1 2cva",
        ...override,
    });
};
