import {
    LandingPageStates,
    OrganisationFeatures,
    OrganisationSettings,
    TranslationLanguage,
} from "../OrganisationDTOs";

export const createOrganisationSettings = (
    override: Partial<OrganisationSettings> = {},
): OrganisationSettings => {
    return {
        success: true,
        organisationId: 0,
        organisationCode: "",
        organisationName: "",
        organisationPhoneNumber: "",
        organisationAddress: {
            addressOne: null,
            addressTwo: null,
            town: null,
            postCode: null,
        },
        feedbackFormLink: null,
        landingPageSettings: {
            bannerHeader: "",
            bannerBody: "",
            bannerSubtext: "",
            pageTitle: "",
        },
        confirmationPageSettings: {
            submissionPageSubtext: "",
        },
        requestTrackingId: "",
        translationLanguages: [],
        submissionPageMessage: null,
        landingPageState: LandingPageStates.ENABLED,
        isDownForMaintenance: false,
        prescriptionSettings: {
            isEnabled: false,
            isRedirectEnabled: false,
            redirectSettings: {
                title: "",
                url: "",
                isPrescriptionFormEnabled: false,
            },
        },
        outOfHoursSettings: {
            showOutOfHoursPage: false,
        },
        ...override,
    };
};

export const createTranslationLanguage = (
    override: Partial<TranslationLanguage> = {},
): TranslationLanguage => {
    return {
        name: "",
        code: "",
        nativeName: "",
        ...override,
    };
};

export const createOrganisationFeatures = (
    override: Partial<OrganisationFeatures> = {},
): OrganisationFeatures => {
    return {
        patientInitiatedNhsLogin: false,
        translationsEnabled: false,
        ...override,
    };
};
