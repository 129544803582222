import { getCookieBannerResponse } from "@accurx/design";
import {
    AnalyticsProperties,
    EventUserType,
    CookieFreeTrackEvent,
    EventObjectType,
    EventAction,
    EventDetails,
} from "@accurx/shared";
import { LandingPageStates } from "../api/organisation/OrganisationDTOs";
import { NhsLoginType } from "../types";
import { MapPropertiesClinicianAppIdToUserId } from "./analytics.utils";

export type TriageBaseProps = {
    anonymousRequestId: string;
    isReceptionFlow: boolean;
    eventUserType: EventUserType;
    loginType: NhsLoginType;
    clinicianAppId: string | null;
    userOrganisationId: number;
};

export type TriageFormStartProps = TriageBaseProps & {
    landingPageState: LandingPageStates;
};

export type TriageFormContinue = TriageBaseProps & {
    pageOrigin: string;
};

export type TrackTriageFormBaseAnalyticsProps = TriageBaseProps & {
    userOrganisationId: number;
    requestType: string;
    isOutOfHours: boolean;
    hasError: boolean;
    clinicianAppId: string | null;
    requestTopic: string;
};

export type TrackTriageFormSubmitAnalyticsProps =
    TrackTriageFormBaseAnalyticsProps & {
        contactMethods: string;
        // patientRequestResponseTime: string | null; // This field is no longer being used but need to check with analytics
        patientAge: number | null;
        isDefaultAssignee: boolean;
        withConfirmationSMS: boolean;
        symptomsTab: "adults" | "children";
    };

export type PatientTriagePanelClickProps = TriageBaseProps & {
    requestType: string;
    panelClicked: string;
};

export type ReceptionPatientTriagePanelClickProps =
    PatientTriagePanelClickProps & {
        clinicianAppId: string | null;
        organisationCode: string;
    };

export type PatientTriageRequestSubmittedProps = TriageBaseProps & {
    contactMethods: string;
    patientAge: number | null;
    numOfPhotos: number;
    requestedConfirmationSms: boolean;
    isOutOfHours: boolean;
    hasProxy: boolean;
    organisationCode: string;
    landingPageState: LandingPageStates;
    requestType: string;
    isDefaultAssignee: boolean;
};

export type PatientTriageOutOfHoursLinkClickedProps = TriageBaseProps & {
    linkName: string;
};

export type TriageFormLanguageProps = {
    anonymousRequestId: string;
    userOrganisationId: number;
};

const isTrackingEnabled = (): boolean =>
    getCookieBannerResponse() === "accepted";
export const trackEvent = (eventDetails: EventDetails): void => {
    const mappedProperties = MapPropertiesClinicianAppIdToUserId(
        eventDetails.properties,
    );

    const fullProps = {
        eventVersion: 5,
        userIsLoggedIn: false,
        ...mappedProperties,
    };

    if (!isTrackingEnabled()) return;

    CookieFreeTrackEvent({
        object: eventDetails.object,
        objectType: eventDetails.objectType,
        action: eventDetails.action,
        properties: fullProps,
    });
};

const TrackPageViewEvent = (
    object: string,
    customProperties: AnalyticsProperties,
): void => {
    trackEvent({
        object: object,
        objectType: EventObjectType.Page,
        action: EventAction.View,
        properties: customProperties,
    });
};

const TrackButtonClickEvent = (
    object: string,
    customProperties: AnalyticsProperties,
): void => {
    trackEvent({
        object: object,
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: customProperties,
    });
};

const TrackButtonResponseEvent = (
    object: string,
    customProperties: AnalyticsProperties,
): void => {
    trackEvent({
        object: object,
        objectType: EventObjectType.Button,
        action: EventAction.Response,
        properties: customProperties,
    });
};

export const trackTriageFormStartPageView = (
    props: TriageFormStartProps,
): void => {
    TrackPageViewEvent("TriageFormStart", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackPatientInitiatedOutOfHoursPageShown = (
    props: TriageFormStartProps,
): void => {
    TrackPageViewEvent("TriageOutOfHour", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackPatientInitiatedOutOfHoursLinkClicked = (
    props: PatientTriageOutOfHoursLinkClickedProps,
): void => {
    TrackButtonClickEvent("TriageOutOfHour", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormRequestTypeClick = (
    props: PatientTriagePanelClickProps,
): void => {
    TrackButtonClickEvent("TriageFormRequestType", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormRequestTopicClick = (
    props: PatientTriagePanelClickProps,
): void => {
    TrackButtonClickEvent("TriageFormRequestTopic", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormContinue = (props: TriageFormContinue): void => {
    TrackButtonClickEvent("TriageFormContinue", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageRequestEmergencyPageView = (
    props: TriageBaseProps,
): void => {
    TrackPageViewEvent("TriageFormEmergency", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageRequestEmergencyContinueClick = (
    props: TriageBaseProps,
): void => {
    trackTriageFormContinue({
        ...props,
        pageOrigin: "ConfirmNotEmergency",
    });
};

export const trackPatientClickedNhsLogin = (props: TriageBaseProps): void => {
    TrackButtonClickEvent("TriageNhsLogin", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormSubmitClick = (
    props: PatientTriageRequestSubmittedProps,
): void => {
    TrackButtonClickEvent("TriageFormSubmit", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormSubmitResponse = (
    props: TrackTriageFormSubmitAnalyticsProps,
): void => {
    TrackButtonResponseEvent("TriageFormSubmit", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackPatientInitiatedReadPhotoUploadRequirements = (
    props: TriageBaseProps,
): void => {
    TrackButtonClickEvent("TriagePhotoUploadRequirements", {
        ...props,
        loginType: toPascalCase(props.loginType),
    });
};

export const trackTriageFormRedirect = (
    props: TrackTriageFormBaseAnalyticsProps & {
        redirectDestination: string | undefined;
    },
): void => {
    const fullProps = {
        userIsLoggedIn: false,
        ...props,
    };

    trackEvent({
        object: "TriageFormRedirect",
        objectType: EventObjectType.Link,
        action: EventAction.Click,
        properties: fullProps,
    });
};

export const trackTriageFormLanguage = (
    props: TriageFormLanguageProps,
): void => {
    trackEvent({
        object: "TriageFormLanguage",
        objectType: EventObjectType.Button,
        action: EventAction.Click,
        properties: props,
    });
};

export const trackTriageFormLanguageSelected = (
    props: TriageFormLanguageProps & {
        languageSelected: string | null;
    },
): void => {
    trackEvent({
        object: "TriageFormLanguage",
        objectType: EventObjectType.MenuItem,
        action: EventAction.Click,
        properties: props,
    });
};

const toPascalCase = (str: string | null): string | null => {
    if (str === null) {
        return null;
    }
    return (str.match(/[a-zA-Z0-9]+/g) || [])
        .map((w) => `${w.charAt(0).toUpperCase()}${w.slice(1)}`)
        .join("");
};
