import { Markdown } from "./MarkdownDTOs";
import { Question } from "./QuestionDTOs";

/**
 * A block defines a single-purpose item on a page, where a series
 * of block(s) define content on a page
 */
export type Block = QuestionBlock | MarkdownBlock;

export type BaseBlock<T extends BlockType = BlockType> = {
    id: string;
    type: T;
    question: null;
    markdown: null;
    nextBlockId: string | null;
};

export enum BlockType {
    Question = "Question",
    Markdown = "Markdown",
}

export type QuestionBlock = Omit<BaseBlock<BlockType.Question>, "question"> & {
    question: Question;
};

export type MarkdownBlock = Omit<BaseBlock<BlockType.Markdown>, "markdown"> & {
    markdown: Markdown;
};
