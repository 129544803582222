import { PageLayoutProviders } from "./PageLayoutProviders";
import {
    StyledHeader,
    StyledLanguageSelector,
    StyledMain,
} from "./PageLayout.styles";

type PageLayoutProps = {
    children?: JSX.Element | null;
    hasPageStyling?: boolean;
};

/**
 * Providing the base layout for the patient initiated flows. This component
 * should be reused across all patient initiated pages for continuous styling
 */

export const PageLayout = ({
    children,
    hasPageStyling,
}: PageLayoutProps): JSX.Element => {
    return (
        <PageLayoutProviders>
            <StyledHeader />
            {!hasPageStyling ? (
                <StyledMain>
                    <StyledLanguageSelector />
                    {children}
                </StyledMain>
            ) : (
                <main>{children}</main>
            )}
        </PageLayoutProviders>
    );
};
