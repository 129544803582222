import {
    BaseMarkdown,
    DropdownMarkdown,
    FeedbackMarkdown,
    FeedbackMarkdownColor,
    MarkdownType,
    TextMarkdown,
} from "../../FlowDTOs";

export const createBaseMarkdown = <T extends MarkdownType>(
    override: Required<Pick<BaseMarkdown<T>, "markdownType">> &
        Partial<Omit<BaseMarkdown<T>, "markdownType">>,
): BaseMarkdown<T> => ({
    id: "",
    content: "",
    colour: null,
    title: null,
    ...override,
});

export const createTextMarkdown = (
    override: Partial<Omit<TextMarkdown, "type">> = {},
): TextMarkdown => ({
    ...createBaseMarkdown<MarkdownType.Text>({
        markdownType: MarkdownType.Text,
        ...override,
    }),
});

export const createFeedbackMarkdown = (
    override: Partial<Omit<FeedbackMarkdown, "type">> = {},
): FeedbackMarkdown => ({
    ...createBaseMarkdown<MarkdownType.Feedback>({
        markdownType: MarkdownType.Feedback,
    }),
    colour: FeedbackMarkdownColor.Success,
    ...override,
});

export const createDropdownMarkdown = (
    override: Partial<Omit<DropdownMarkdown, "type">> = {},
): DropdownMarkdown => ({
    ...createBaseMarkdown<MarkdownType.Dropdown>({
        markdownType: MarkdownType.Dropdown,
    }),
    title: "",
    ...override,
});
