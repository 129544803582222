import { createContext, ReactNode, useContext, useState } from "react";
import { DEFAULT_LANGUAGE } from "../settings";
import { LandingPageSettings, TranslationLanguage } from "../api/organisation";
import { QuestionBlock, Section } from "../api/sections";
import { meta } from "../constant/locales";
import {
    DEFAULT_RECIPIENT_PATIENT_DETAILS,
    RecipientDetailsQuestions,
    VERIFICATION_QUESTION,
} from "../constant/patientDetails";

export type Meta = typeof meta;

export type Content = {
    // dynamic header content
    landingPageSettingsContent: LandingPageSettings;
    // dynamic form content
    sections: Section[];
    // static content
    meta: Meta;
    // patient details form content
    recipientDetailsQuestions: RecipientDetailsQuestions;
    verificationQuestion: QuestionBlock;
};

type TranslationContent = Record<string, Content>;

export type ContentContextState = {
    sections: Section[];
    meta: typeof meta;
    recipientDetailsQuestions: RecipientDetailsQuestions;
    verificationQuestion: QuestionBlock;
    landingPageSettingsContent: LandingPageSettings;
    setContentForLanguage(payload: {
        language: string;
        content: Partial<Content>;
    }): void;
    selectedLanguage: TranslationLanguage;
    setSelectedLanguage(language: TranslationLanguage): void;
};

export const defaultContent: Content = {
    sections: [],
    meta: meta,
    recipientDetailsQuestions: DEFAULT_RECIPIENT_PATIENT_DETAILS,
    verificationQuestion: VERIFICATION_QUESTION,
    landingPageSettingsContent: {
        pageTitle: "",
        bannerBody: "",
        bannerSubtext: "",
        bannerHeader: "",
    },
};

const defaultTranslationContent: TranslationContent = {
    en: defaultContent,
};

export const defaultState: ContentContextState = {
    ...defaultContent,
    selectedLanguage: DEFAULT_LANGUAGE,
    setContentForLanguage: () => {},
    setSelectedLanguage: () => {},
};

export const ContentContext = createContext<ContentContextState>(defaultState);

export const useContent = (): ContentContextState => {
    const context = useContext(ContentContext);

    // If we're on the client-side, ensure that anything trying to use the useContent hook is wrapped in the provider
    if (typeof window !== "undefined" && context === undefined) {
        throw new Error(
            "This component is a content context consumer, it must be used within a ContentProvider",
        );
    }

    return context;
};

export const ContentProvider = ({
    children,
    initialContextState = {},
}: {
    children: ReactNode;
    initialContextState?: Partial<ContentContextState>;
}): JSX.Element => {
    const [selectedLanguage, setSelectedLanguage] =
        useState<TranslationLanguage>(DEFAULT_LANGUAGE);
    const [translationContent, setTranslationContent] =
        useState<TranslationContent>(defaultTranslationContent);

    const setContentForLanguage = ({
        language,
        content,
    }: {
        language: string;
        content: Partial<Content>;
    }): void => {
        const existingContentForLanguage = translationContent[language] ?? {};
        setTranslationContent({
            ...translationContent,
            [language]: { ...existingContentForLanguage, ...content },
        });
    };

    return (
        <ContentContext.Provider
            value={{
                sections:
                    translationContent[selectedLanguage.code]?.sections ?? [],
                meta: translationContent[selectedLanguage.code]?.meta ?? meta,
                recipientDetailsQuestions:
                    translationContent[selectedLanguage.code]
                        ?.recipientDetailsQuestions ??
                    defaultContent.recipientDetailsQuestions,
                verificationQuestion:
                    translationContent[selectedLanguage.code]
                        ?.verificationQuestion ??
                    defaultContent.verificationQuestion,
                landingPageSettingsContent:
                    translationContent[selectedLanguage.code]
                        .landingPageSettingsContent ??
                    defaultContent.landingPageSettingsContent,
                setContentForLanguage,
                selectedLanguage,
                setSelectedLanguage,
                ...initialContextState,
            }}
        >
            {children}
        </ContentContext.Provider>
    );
};
