/**
 * A Question (eg 'Describe the medical problem') defines a form Question for a patient
 * to submit information about their issue.
 */
export type Question =
    | FreeTextQuestion
    | SingleAnswerQuestion
    | MultipleAnswerQuestion
    | DateQuestion
    | DropdownQuestion
    | AddOptionQuestion
    | FreeTextWithAttachmentQuestion;

/**
 * All Questions have all Question properties. BaseQuestion sets a default state where
 * non-required properties are set to null. This sets a base type to override when composing
 * Question-type-specific types on top.
 */

export type BaseQuestion<T extends QuestionType = QuestionType> = {
    id: string;
    questionType: T;
    text: string;
    subtext: string | null;
    errorText: string | null;
    isOptional: boolean;
    options: null;
    optionPrefix: null;
    colour: null;
};

export enum QuestionType {
    FreeText = "FreeText",
    SingleAnswer = "SingleAnswer",
    MultipleAnswer = "MultipleAnswer",
    Date = "Date",
    Dropdown = "Dropdown",
    AddOption = "AddOption",
    FreeTextWithAttachment = "FreeTextWithAttachment",
}

export type FreeTextQuestion = BaseQuestion<QuestionType.FreeText>;
export type DateQuestion = BaseQuestion<QuestionType.Date>;
export type FreeTextWithAttachmentQuestion =
    BaseQuestion<QuestionType.FreeTextWithAttachment>;

export type MultiOptionsQuestion<
    T extends
        | QuestionType.SingleAnswer
        | QuestionType.MultipleAnswer
        | QuestionType.Dropdown,
> = Omit<BaseQuestion<T>, "options"> & {
    options: Option[];
};

export type SingleAnswerQuestion =
    MultiOptionsQuestion<QuestionType.SingleAnswer>;
export type MultipleAnswerQuestion =
    MultiOptionsQuestion<QuestionType.MultipleAnswer>;
export type DropdownQuestion = MultiOptionsQuestion<QuestionType.Dropdown>;

export type AddOptionQuestion = Omit<
    BaseQuestion<QuestionType.AddOption>,
    "optionPrefix"
> & {
    optionPrefix: string;
};

export type Option = {
    id: string;
    text: string;
    nextPageId: string | null;
    nextBlockId: string | null;
};
