import { Tokens, Item } from "@accurx/design";
import styled from "styled-components";

export const StyledHeaderBackground = styled.nav`
    background-color: ${Tokens.COLOURS.primary.green[100]};
    width: 100%;
    padding: ${Tokens.SIZES[1]};
`;

export const StyledItem = styled(Item)`
    background: ${Tokens.COLOURS.greyscale.white};
    width: 75px;
    height: 75px;
    border-radius: ${Tokens.SIZES[0.5]};
    margin: 0 ${Tokens.SIZES[1]};
    border: 1px solid ${Tokens.COLOURS.greyscale.white};
    justify-content: center;
    display: flex;
`;
