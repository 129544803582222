import { AnalyticsProperties } from "@accurx/shared";

export const MapPropertiesClinicianAppIdToUserId = (
    properties: AnalyticsProperties | undefined,
): AnalyticsProperties | undefined => {
    //So the data can be analysed correctly in mixpanel, userId needs to be populated with clinicianAppId if present, otherwise passing an empty string populates a dummy userId in the tracking library
    if (properties) {
        const clinicianAppId = properties["clinicianAppId"];
        properties["userId"] = clinicianAppId == null ? "" : clinicianAppId;
        delete properties["clinicianAppId"];
    }
    return properties;
};
