import { createContext, ReactNode, useContext, useState } from "react";

export type FileUploadDataProps = {
    attachmentId: string | null;
    file: File | null;
    dataUrl: string | null;
};

export type PhotoUploadContextState = {
    uploadedFileData: FileUploadDataProps | null;
    setUploadedFileData: (uploadedFileData: FileUploadDataProps | null) => void;
    photoGuidanceAccepted: boolean;
    setPhotoGuidanceAccepted: (photoGuidanceAccepted: boolean) => void;
    uploadedAttachments: FileUploadDataProps[];
    setUploadedAttachments: (
        uploadedAttachments: FileUploadDataProps[],
    ) => void;
    resetPhotoUploadState: () => void;
};

export const defaultState: PhotoUploadContextState = {
    uploadedFileData: {
        attachmentId: null,
        file: null,
        dataUrl: null,
    },
    setUploadedFileData: () => {},
    photoGuidanceAccepted: false,
    setPhotoGuidanceAccepted: () => {},
    uploadedAttachments: [],
    setUploadedAttachments: () => {},
    resetPhotoUploadState: () => {},
};

export const PhotoUploadContext =
    createContext<PhotoUploadContextState>(defaultState);

export const usePhotoUpload = (): PhotoUploadContextState => {
    const context = useContext(PhotoUploadContext);

    // If we're on the client-side, ensure that anything trying to use the usePhotoUpload hook is wrapped in the provider
    if (typeof window !== "undefined" && context === undefined) {
        throw new Error(
            "This component is a PhotoUpload context consumer, it must be used within a PhotoUploadProvider",
        );
    }

    return context;
};

export const PhotoUploadProvider = ({
    children,
    initialContextState = {},
}: {
    children: ReactNode;
    initialContextState?: Partial<PhotoUploadContextState>;
}): JSX.Element => {
    const [uploadedFileData, setUploadedFileData] =
        useState<FileUploadDataProps | null>(
            {
                attachmentId: null,
                file: null,
                dataUrl: null,
            } || null,
        );
    const [photoGuidanceAccepted, setPhotoGuidanceAccepted] =
        useState<boolean>(false);

    const [uploadedAttachments, setUploadedAttachments] = useState<
        FileUploadDataProps[]
    >([]);

    const resetPhotoUploadState = () => {
        setUploadedAttachments([]);
        setUploadedFileData(null);
        setPhotoGuidanceAccepted(false);
    };

    return (
        <PhotoUploadContext.Provider
            value={{
                uploadedFileData,
                setUploadedFileData,
                photoGuidanceAccepted,
                setPhotoGuidanceAccepted,
                uploadedAttachments,
                setUploadedAttachments,
                resetPhotoUploadState,
                ...initialContextState,
            }}
        >
            {children}
        </PhotoUploadContext.Provider>
    );
};
