import {
    BaseQuestion,
    DateQuestion,
    DropdownQuestion,
    AddOptionQuestion,
    FreeTextQuestion,
    FreeTextWithAttachmentQuestion,
    MultipleAnswerQuestion,
    QuestionType,
    SingleAnswerQuestion,
    Option,
} from "../../FlowDTOs";

export const createBaseQuestion = <T extends QuestionType>(
    override: Required<Pick<BaseQuestion<T>, "questionType">> &
        Partial<Omit<BaseQuestion<T>, "questionType">>,
): BaseQuestion<T> => ({
    id: "",
    text: "",
    subtext: null,
    errorText: null,
    isOptional: false,
    options: null,
    optionPrefix: null,
    colour: null,
    ...override,
});

export const createFreeTextQuestion = (
    override: Omit<Partial<FreeTextQuestion>, "questionType"> = {},
): FreeTextQuestion => ({
    ...createBaseQuestion<QuestionType.FreeText>({
        questionType: QuestionType.FreeText,
        ...override,
    }),
});

export const createDateQuestion = (
    override: Omit<Partial<DateQuestion>, "questionType"> = {},
): DateQuestion => ({
    ...createBaseQuestion<QuestionType.Date>({
        questionType: QuestionType.Date,
        ...override,
    }),
});

export const createFreeTextWithAttachmentQuestion = (
    override: Omit<
        Partial<FreeTextWithAttachmentQuestion>,
        "questionType"
    > = {},
): FreeTextWithAttachmentQuestion => ({
    ...createBaseQuestion<QuestionType.FreeTextWithAttachment>({
        questionType: QuestionType.FreeTextWithAttachment,
        ...override,
    }),
});

export const createSingleAnswerQuestion = (
    override: Omit<Partial<SingleAnswerQuestion>, "questionType"> = {},
): SingleAnswerQuestion => ({
    ...createBaseQuestion<QuestionType.SingleAnswer>({
        questionType: QuestionType.SingleAnswer,
    }),
    options: [],
    ...override,
});

export const createMultipleAnswerQuestion = (
    override: Omit<Partial<MultipleAnswerQuestion>, "questionType"> = {},
): MultipleAnswerQuestion => ({
    ...createBaseQuestion<QuestionType.MultipleAnswer>({
        questionType: QuestionType.MultipleAnswer,
    }),
    options: [],
    ...override,
});

export const createDropdownQuestion = (
    override: Omit<Partial<DropdownQuestion>, "questionType"> = {},
): DropdownQuestion => ({
    ...createBaseQuestion<QuestionType.Dropdown>({
        questionType: QuestionType.Dropdown,
    }),
    options: [],
    ...override,
});

export const createAddOptionQuestion = (
    override: Omit<Partial<AddOptionQuestion>, "questionType"> = {},
): AddOptionQuestion => ({
    ...createBaseQuestion<QuestionType.AddOption>({
        questionType: QuestionType.AddOption,
    }),
    optionPrefix: "",
    ...override,
});

export const createOption = (override: Partial<Option> = {}): Option => ({
    id: "",
    text: "",
    nextPageId: null,
    nextBlockId: null,
    ...override,
});
