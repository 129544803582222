import { Button, Text } from "@accurx/design";
import { StyledHeader, StyledTextWrapper } from "./NHSHeader.styles";
import { useContent } from "../../../context/ContentContext";
import { useRouter } from "next/router";
import { PATHS } from "../../../constant/paths";
import { HTMLAttributes } from "react";

export const NHS_PAGE = {
    ADVICE: "ADVICE",
    MESSAGES: "MESSAGES",
} as const;

type NHSHeaderProps = {
    props?: HTMLAttributes<"div">;
};

export const NHSHeader = ({ ...props }: NHSHeaderProps): JSX.Element => {
    const { meta } = useContent();
    const router = useRouter();
    const handleBackClick = (): void => {
        const isNhsNavigation = router.route.includes(
            PATHS.confirmNotEmergency,
        );

        if (!isNhsNavigation) {
            router.back();
            return;
        }

        window.nhsapp?.navigation.goToPage(
            router.query.requestType === "admin"
                ? NHS_PAGE.ADVICE
                : NHS_PAGE.MESSAGES,
        );
    };

    return (
        <StyledHeader {...props}>
            <Button
                theme="transparent"
                onClick={handleBackClick}
                text={meta.back}
                aria-label={meta.back}
                icon={{
                    name: "Arrow",
                    rotation: "left",
                    colour: "blue",
                    title: "Back",
                }}
            />
            <StyledTextWrapper>
                <Text variant="label">
                    This service is provided by your GP surgery
                </Text>
            </StyledTextWrapper>
        </StyledHeader>
    );
};
