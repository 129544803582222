import { Button, Ds, Text } from "@accurx/design";
import { useContent } from "../../../context/ContentContext";

type LanguageChangeModalProps = {
    onClose(): void;
};

export const LanguageChangeModal = ({
    onClose,
}: LanguageChangeModalProps): JSX.Element => {
    const { meta } = useContent();

    return (
        <Ds.Modal open={true} onOpenChange={onClose}>
            <Ds.Modal.Section>
                <Ds.Modal.Title>{meta.translationModal.title}</Ds.Modal.Title>
            </Ds.Modal.Section>
            <Ds.Modal.Divider />
            <Ds.Modal.Section>
                <Text>{meta.translationModal.body}</Text>
            </Ds.Modal.Section>
            <Ds.Modal.Divider />
            <Ds.Modal.Section>
                <Button
                    onClick={onClose}
                    text={meta.translationModal.understandButton}
                />
            </Ds.Modal.Section>
        </Ds.Modal>
    );
};
