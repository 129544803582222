import { useCallback } from "react";
import { useIdleTimerSetup } from "../../../utils/IdleTimer/IdleTimer";
import { useNhsApp } from ".";
import { useRouter } from "next/router";
import { useOrganisation } from "../context/OrganisationContext";

export const IDLE_TIMEOUT = 630000; // 10mins 30secs

export const useIdleTimer = (): void => {
    const isUsingNhsApp = useNhsApp();
    const router = useRouter();
    const { organisationSettings } = useOrganisation();

    const refreshPageOnTimeout = useCallback(() => {
        if (isUsingNhsApp && window.nhsapp?.navigation) {
            window.nhsapp.navigation.goToHomepage();
            return;
        } else {
            router.push(
                `/patient-initiated/${organisationSettings?.organisationCode}`,
            );
        }
    }, [isUsingNhsApp]);

    useIdleTimerSetup({ onIdle: refreshPageOnTimeout, timeout: IDLE_TIMEOUT });
};
