export type Markdown = TextMarkdown | FeedbackMarkdown | DropdownMarkdown;

export type BaseMarkdown<T extends MarkdownType = MarkdownType> = {
    id: string;
    markdownType: T;
    content: string;
    colour: null;
    title: null;
};

export enum MarkdownType {
    Text = "Text",
    Feedback = "Feedback",
    Dropdown = "Dropdown",
}

export type TextMarkdown = BaseMarkdown<MarkdownType.Text>;
export type FeedbackMarkdown = Omit<
    BaseMarkdown<MarkdownType.Feedback>,
    "colour"
> & {
    colour: FeedbackMarkdownColor;
};
export type DropdownMarkdown = Omit<
    BaseMarkdown<MarkdownType.Dropdown>,
    "title"
> & {
    title: string;
};

export enum FeedbackMarkdownColor {
    Success = "Success",
    Secondary = "Secondary",
    Information = "Information",
    Warning = "Warning",
    Error = "Error",
    Learning = "Learning",
}
