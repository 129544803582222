import { getApiEndpoint, httpClient } from "@accurx/shared";
import { TranslationResponse } from "./TranslationDTOs";

const ENDPOINTS = {
    TRANSLATION:
        "/api/patientinitiated/translation/organisation/:organisationCode",
} as const;

export const fetchTranslations = async <T>({
    organisationCode,
    language,
    siteContent,
    requestTrackingId,
}: {
    organisationCode: string;
    language: string;
    siteContent: T;
    requestTrackingId: string;
}): Promise<TranslationResponse<T> | null> => {
    const url = getApiEndpoint({
        path: ENDPOINTS.TRANSLATION,
        params: { organisationCode },
        query: `?patientRequestId=${requestTrackingId}`,
    });

    const response = await httpClient.postJsonReturnJsonSafeAsync(url, {
        translatedToLanguage: language,
        staticContentToTranslate: siteContent,
    });

    return response?.result ?? null;
};
