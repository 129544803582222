import { Block, QuestionBlock } from "../api/sections";
import { createQuestionBlock } from "../api/sections/factory/Flow/block.factory";
import {
    createDateQuestion,
    createFreeTextQuestion,
    createMultipleAnswerQuestion,
    createOption,
    createSingleAnswerQuestion,
} from "../api/sections/factory/Flow/question.factory";
import { Recipient } from "../settings";

export type PatientDetails = {
    patientQuestions: Block[];
    proxyQuestions: Block[];
};

export type RecipientDetailsQuestions = Record<Recipient, PatientDetails>;

/**
 * NB: ids are hard-coded here deliberately - if we generated them on the fly
 * then it might have implications with caching translations, which would add extra
 * costs to the feature
 */
export const PATIENT_DETAILS_QUESTION_ID = {
    FIRST_NAME: "37dc4c89-55cb-4f21-9835-f9be4f23abbd",
    LAST_NAME: "b001f685-e94a-4368-b6ed-d94d918e2c9f",
    DATE_OF_BIRTH: "9ed9e8c7-56bb-429b-b1d2-a2294c38260d",
    POSTCODE: "e749fd88-1ed8-4c01-9ace-241afdfb8f62",
    PHONE: "852aa513-0692-4374-a755-4c359ae3f0e4",
    CONTACT_PREFERENCES: "fb929f5b-937b-4241-bbab-d1a3a57ea322",
    VERIFY_IDENTITY: "651ca060-4d7d-44f0-8db0-8b85f169dd40",
    CHOOSE_CLINICIAN_ID: "5099414b-aa2b-4c43-a13a-ad1b5c0a5d95",
};

export const PROXY_DETAILS_QUESTION_ID = {
    PROXY_FIRST_NAME: "9a692d8e-6c22-4ca4-9e83-efe051d50920",
    PROXY_LAST_NAME: "4d54ba6b-de42-4944-a31a-e613829faf78",
    PROXY_PHONE: "fe7a372e-1394-4b19-bf9f-0d6f38e70af5",
    RELATIONSHIP: "d891d0c7-f354-4017-a107-1b812ecf5114",
    WHO_TO_CONTACT: "c0a639c7-023d-436d-9bc2-4243ac74d2bb",
    SEND_CONFIRMATION: "1e7475d2-93a2-4648-acd1-85fd23ff081d",
};

export const VERIFICATION_QUESTION_ID = {
    CODE: "cbbdadbd-f8a7-43ec-b9df-f241ab5110cc",
};

const linkBlocks = (blocks: Block[]): Block[] =>
    blocks.map((block, index) => {
        if (index === blocks.length - 1) return block;
        return { ...block, nextBlockId: blocks[index + 1]?.id ?? null };
    });

export const PATIENT_DETAILS_QUESTIONS: Block[] = linkBlocks([
    createQuestionBlock({
        id: "06ff387a-9a25-4ea1-a806-01c34466126c",
        question: createFreeTextQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.FIRST_NAME,
            text: "First name",
            isOptional: false,
            errorText: "Enter your first name",
        }),
    }),
    createQuestionBlock({
        id: "bb1e2421-1cd1-4754-ad7f-dc4e690cdc5c",
        question: createFreeTextQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.LAST_NAME,
            text: "Last name",
            isOptional: false,
            errorText: "Enter your last name",
        }),
    }),
    createQuestionBlock({
        id: "76c1806e-d599-4681-a124-dc523962845e",
        question: createDateQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.DATE_OF_BIRTH,
            text: "Date of birth",
            subtext: "For example 26 2 1956.",
            isOptional: false,
            errorText: "Enter your date of birth",
        }),
    }),
    createQuestionBlock({
        id: "89003913-d982-44d2-963b-10b00df1ec53",
        question: createFreeTextQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.POSTCODE,
            text: "Postcode",
            isOptional: false,
            errorText: "Enter your UK postcode",
        }),
    }),
    createQuestionBlock({
        id: "4d3e61f1-c75f-4dd5-b8ce-3ae7324227ee",
        question: createFreeTextQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.PHONE,
            text: "Phone number",
            subtext: "A mobile number is preferred.",
            isOptional: false,
            errorText: "Enter your UK phone number",
        }),
    }),
    createQuestionBlock({
        id: "b93a0cf4-d811-4267-a0bf-a47df1578b2c",
        question: createMultipleAnswerQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.CONTACT_PREFERENCES,
            text: "How would you like to be contacted?",
            subtext:
                "While we do our best to follow your preferences, this may not always be possible.",
            options: [
                createOption({
                    id: "preference-option-text",
                    text: "Text message",
                }),
                createOption({
                    id: "preference-option-phone",
                    text: "Phone call",
                }),
            ],
            errorText: "Select a contact preference",
        }),
    }),
    createQuestionBlock({
        id: "3b84d7e2-4e39-4707-912a-e04d6e77a822",
        question: createSingleAnswerQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.VERIFY_IDENTITY,
            text: "Do you have access to this phone right now?",
            subtext: "This will help process your request faster.",
            options: [
                createOption({
                    id: "verify-option-accept",
                    text: "Yes, verify",
                }),
                createOption({
                    id: "verify-option-decline",
                    text: "No, skip this step",
                }),
            ],
            isOptional: false,
            errorText: "Confirm whether you would like to verify your identity",
        }),
    }),
]);

export const RECEPTION_PATIENT_DETAILS_QUESTIONS: Block[] = linkBlocks([
    createQuestionBlock({
        id: "62bf001c-c874-4cfb-826e-ce68d118a5f2",
        question: createMultipleAnswerQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.CONTACT_PREFERENCES,
            text: "How would you like to be contacted?",
            subtext:
                "While we do our best to follow your preferences, this may not always be possible.",
            options: [
                createOption({
                    id: "reception-preference-option-text",
                    text: "Text message",
                }),
                createOption({
                    id: "reception-preference-option-phone",
                    text: "Phone call",
                }),
            ],
            errorText: "Select a contact preference",
        }),
    }),
]);

export const RECEPTION_PATIENT_PHONE_QUESTION: QuestionBlock =
    createQuestionBlock({
        id: "b8cffa66-7b20-4492-ad45-0d9a256a6dd4",
        question: createFreeTextQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.PHONE,
            text: "Confirm contact number",
            isOptional: false,
            errorText: "Enter a valid UK number",
        }),
    });

export const RECEPTION_PATIENT_POSTCODE_QUESTION: QuestionBlock =
    createQuestionBlock({
        id: "0b67bac0-ca3f-4e1d-9cff-64de6fbdf07e",
        question: createFreeTextQuestion({
            id: PATIENT_DETAILS_QUESTION_ID.POSTCODE,
            text: "Postcode",
            isOptional: true,
        }),
    });

export const KNOWN_CLINICIAN: Block = createQuestionBlock({
    id: "77f22a31-cf35-42d1-a9b1-c963ba055357",
    question: createFreeTextQuestion({
        id: PATIENT_DETAILS_QUESTION_ID.CHOOSE_CLINICIAN_ID,
        text: "Would you like to speak with a particular person?",
        subtext: `If you have seen someone about this particular problem before, 
            it is best to see the same person again. 
            However, we cannot guarantee this, and it may mean waiting a little longer for an appointment.`,
        isOptional: true,
    }),
});

export const PROXY_DETAILS_QUESTIONS: Block[] = linkBlocks([
    createQuestionBlock({
        id: "0952183a-d4e6-488f-aef9-589b77df6a37",
        question: createFreeTextQuestion({
            id: PROXY_DETAILS_QUESTION_ID.PROXY_FIRST_NAME,
            text: "Your first name",
            isOptional: false,
            errorText: "Enter your first name",
        }),
    }),
    createQuestionBlock({
        id: "4aec9820-2884-4d7f-aaf7-5529c44d67be",
        question: createFreeTextQuestion({
            id: PROXY_DETAILS_QUESTION_ID.PROXY_LAST_NAME,
            text: "Your last name",
            isOptional: false,
            errorText: "Enter your last name",
        }),
    }),
    createQuestionBlock({
        id: "f6c15919-0dc1-4d8f-95d3-dc393338c7bf",
        question: createFreeTextQuestion({
            id: PROXY_DETAILS_QUESTION_ID.PROXY_PHONE,
            text: "Your phone number",
            subtext: "A mobile number is preferred.",
            isOptional: false,
            errorText: "Enter your UK phone number",
        }),
    }),
    createQuestionBlock({
        id: "b77ad563-9b83-4111-9af1-6692f6a03877",
        question: createFreeTextQuestion({
            id: PROXY_DETAILS_QUESTION_ID.RELATIONSHIP,
            text: "Your relationship to patient",
            isOptional: false,
            errorText: "Enter your relationship to the patient",
        }),
    }),
]);

export const VERIFICATION_QUESTION: QuestionBlock = createQuestionBlock({
    id: "4d8775a8-4c81-4f3e-9c3e-8eed0d8c75c7",
    question: createFreeTextQuestion({
        id: VERIFICATION_QUESTION_ID.CODE,
        text: "Verification code",
        subtext: "It should be 6 digits",
        errorText: "Enter a valid access code",
        isOptional: false,
    }),
});

export const PATIENT_DETAILS: {
    patientQuestions: Block[];
    proxyQuestions: Block[];
} = {
    patientQuestions: PATIENT_DETAILS_QUESTIONS,
    proxyQuestions: [],
};

export const RECEPTION_PATIENT_DETAILS: {
    patientQuestions: Block[];
    proxyQuestions: Block[];
} = {
    patientQuestions: RECEPTION_PATIENT_DETAILS_QUESTIONS,
    proxyQuestions: [],
};

export const RECEPTION_PROXY_PATIENT_DETAILS: {
    patientQuestions: Block[];
    proxyQuestions: Block[];
} = {
    patientQuestions: RECEPTION_PATIENT_DETAILS_QUESTIONS.filter(
        ({ question }) =>
            question?.id !== PATIENT_DETAILS_QUESTION_ID.VERIFY_IDENTITY,
    ).map((Question) => ({
        ...Question,
        errorText:
            Question.question?.errorText?.replace("your", "the patient's") ??
            null,
    })),
    proxyQuestions: PROXY_DETAILS_QUESTIONS,
};

export const PROXY_PATIENT_DETAILS: {
    patientQuestions: any;
    proxyQuestions: Block[];
} = {
    patientQuestions: PATIENT_DETAILS_QUESTIONS.filter(
        ({ question }) =>
            question?.id !== PATIENT_DETAILS_QUESTION_ID.VERIFY_IDENTITY,
    ).map((Question) => {
        const updatedErrorText = Question.question?.errorText?.replace(
            "your",
            "the patient's",
        );

        return {
            ...Question,
            question: {
                ...Question.question,
                errorText: updatedErrorText ?? Question.question?.errorText,
            },
        };
    }),
    proxyQuestions: PROXY_DETAILS_QUESTIONS,
};

export const PROXY_CONTACT_PREFERENCE: Block[] = linkBlocks([
    createQuestionBlock({
        id: "additional-question-block-id-1",
        question: createSingleAnswerQuestion({
            id: PROXY_DETAILS_QUESTION_ID.WHO_TO_CONTACT,
            text: "Who should we contact about this request?",
            options: [
                createOption({
                    id: "contact-patient",
                    text: "The patient",
                }),
                createOption({
                    id: "contact-proxy",
                    text: "Myself",
                }),
            ],
        }),
        nextBlockId: null,
    }),
    createQuestionBlock({
        id: "additional-question-block-id-2",
        question: createMultipleAnswerQuestion({
            id: PROXY_DETAILS_QUESTION_ID.SEND_CONFIRMATION,
            text: "We will send a confirmation text to the patient once the request is submitted. Would you also like to receive it?",
            options: [
                createOption({
                    id: "send-confirmation-proxy",
                    text: "Yes, send me a confirmation text",
                }),
            ],
        }),
        nextBlockId: null,
    }),
]);

export const DEFAULT_RECIPIENT_PATIENT_DETAILS: RecipientDetailsQuestions = {
    [Recipient.myself]: PATIENT_DETAILS,
    [Recipient.someoneElse]: PROXY_PATIENT_DETAILS,
    [Recipient.patient]: RECEPTION_PATIENT_DETAILS,
};

export const RECEPTION_RECIPIENT_PATIENT_DETAILS: RecipientDetailsQuestions = {
    [Recipient.patient]: RECEPTION_PATIENT_DETAILS,
    [Recipient.someoneElse]: RECEPTION_PROXY_PATIENT_DETAILS,
    [Recipient.myself]: PATIENT_DETAILS,
};
