import { createContext, ReactNode, useContext, useState } from "react";
import { EventUserType } from "@accurx/shared/dist/Analytics/AnalyticsConstants";
import { TriageBaseProps } from "../analytics/PatientInitiatedEvents";

export const DEFAULT_BASE_ANALYTICS = {
    anonymousRequestId: "",
    isReceptionFlow: false,
    eventUserType: EventUserType.Patient,
    loginType: null,
    clinicianAppId: null,
    userOrganisationId: 0,
};

export type AnalyticsContextState = {
    baseAnalytics: TriageBaseProps | null;
    setBaseAnalytics: (baseAnalytics: TriageBaseProps) => void;
};

export const defaultState: AnalyticsContextState = {
    baseAnalytics: DEFAULT_BASE_ANALYTICS,
    setBaseAnalytics: (_analyticsProps: TriageBaseProps) => {},
};

export const AnalyticsContext =
    createContext<AnalyticsContextState>(defaultState);

export const useAnalytics = (): AnalyticsContextState => {
    const context = useContext(AnalyticsContext);
    // If we're on the client-side, ensure that anything trying to use the useAnalytics hook is wrapped in the provider
    if (typeof window !== "undefined" && context === undefined) {
        throw new Error(
            "This component is an Analytics context consumer, it must be used within a AnalyticsProvider",
        );
    }

    return context;
};

export const AnalyticsProvider = ({
    children,
    initialContextState = {},
}: {
    children: ReactNode;
    initialContextState?: Partial<AnalyticsContextState>;
}): JSX.Element => {
    const [baseAnalytics, setBaseAnalytics] = useState<TriageBaseProps | null>(
        null,
    );

    return (
        <AnalyticsContext.Provider
            value={{
                baseAnalytics,
                setBaseAnalytics,
                ...initialContextState,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};
