import {
    createAddOptionQuestion,
    createDropdownQuestion,
    createFreeTextQuestion,
    createFreeTextWithAttachmentQuestion,
    createMultipleAnswerQuestion,
    createOption,
    createSingleAnswerQuestion,
} from "./question.factory";
import {
    BaseBlock,
    FeedbackMarkdownColor,
    MarkdownBlock,
    QuestionBlock,
    Block,
    BlockType,
} from "../../FlowDTOs";
import {
    createFeedbackMarkdown,
    createTextMarkdown,
    createDropdownMarkdown,
} from "./markdown.factory";

export const createBaseBlock = <T extends BlockType>(
    override: Required<Pick<BaseBlock<T>, "type">> &
        Partial<Omit<BaseBlock<T>, "type">>,
): BaseBlock<T> => ({
    id: "",
    question: null,
    markdown: null,
    nextBlockId: null,
    ...override,
});

export const createQuestionBlock = (
    override: Partial<QuestionBlock> = {},
): QuestionBlock => ({
    ...createBaseBlock<BlockType.Question>({
        type: BlockType.Question,
    }),
    question: createFreeTextQuestion(),
    ...override,
});

export const createMarkdownBlock = (
    override: Partial<MarkdownBlock> = {},
): MarkdownBlock => ({
    ...createBaseBlock<BlockType.Markdown>({
        type: BlockType.Markdown,
    }),
    markdown: createTextMarkdown(),
    ...override,
});

export const createMockBlocks = (): Block[] => [
    createMarkdownBlock({
        id: "text-block-id-1",
        markdown: createTextMarkdown({
            id: "title-markdown-id",
            content: "# Medical request",
        }),
        nextBlockId: "feedback-block-id-1",
    }),
    createMarkdownBlock({
        id: "feedback-block-id-1",
        markdown: createFeedbackMarkdown({
            id: "feedback-markdown-id",
            content:
                "Make it clear if you are filling this in on behalf of someone else.",
            colour: FeedbackMarkdownColor.Information,
        }),
        nextBlockId: "dropdown-block-id-1",
    }),
    createMarkdownBlock({
        id: "dropdown-block-id-1",
        markdown: createDropdownMarkdown({
            id: "dropdown-markdown-id",
            content: "This should appear on click",
            title: "Click me",
        }),
        nextBlockId: "question-block-id-1",
    }),
    createQuestionBlock({
        id: "question-block-id-1",
        question: createFreeTextQuestion({
            id: "free-text-question-id",
            text: "Please describe the medical problem",
            subtext: "For example, I have back pain",
            errorText: "Enter the medical problem",
        }),
    }),
    createQuestionBlock({
        id: "question-block-id-2",
        question: createSingleAnswerQuestion({
            id: "single-answer-question-id",
            text: "Have you previously had a note about this?",
            options: [
                createOption({
                    id: "single-answer-question-id-option-1",
                    text: "Yes",
                }),
                createOption({
                    id: "single-answer-question-id-option-2",
                    text: "No",
                }),
            ],
            errorText: "Select if you've already had a note about this or not",
        }),
        nextBlockId: "question-block-id-2",
    }),
    createQuestionBlock({
        id: "question-block-id-3",
        question: createMultipleAnswerQuestion({
            id: "multiple-answer-question-id",
            text: "Have you had any of the following tests or investigations for this problem?",
            options: [
                createOption({
                    id: "multiple-answer-question-id-option-1",
                    text: "X-ray",
                }),
                createOption({
                    id: "multiple-answer-question-id-option-2",
                    text: "MRI or CT scan",
                }),
                createOption({
                    id: "multiple-answer-question-id-option-3",
                    text: "Ultrasound scan",
                }),
                createOption({
                    id: "multiple-answer-question-id-option-4",
                    text: "Blood tests",
                }),
            ],
            isOptional: true,
        }),
        nextBlockId: "question-block-id-4",
    }),
    createQuestionBlock({
        id: "question-block-id-4",
        question: createFreeTextWithAttachmentQuestion({
            id: "free-text-with-attachment-question-id",
            text: "Describe the medical problem",
            subtext: "For example, I have back pain.",
            errorText: "Enter the medical problem",
            isOptional: true,
        }),
        nextBlockId: "question-block-id-5",
    }),
    createQuestionBlock({
        id: "question-block-id-5",
        question: createDropdownQuestion({
            id: "dropdown-question-id",
            text: "Select test type",
            subtext:
                "If you choose other, specify the test in the field below.",
            errorText: "Select the type of test",
            options: [
                createOption({
                    id: "dropdown-question-id-option-1",
                    text: "Blood test",
                }),
                createOption({
                    id: "dropdown-question-id-option-2",
                    text: "CT scan",
                }),
                createOption({
                    id: "dropdown-question-id-option-3",
                    text: "Ultrasound",
                }),
                createOption({
                    id: "dropdown-question-id-option-4",
                    text: "Urine test",
                }),
                createOption({
                    id: "dropdown-question-id-option-5",
                    text: "Other",
                }),
            ],
        }),
        nextBlockId: "question-block-id-6",
    }),
    createQuestionBlock({
        id: "question-block-id-6",
        question: createAddOptionQuestion({
            id: "options-question-id",
            text: "Name of medications",
            subtext:
                "List each medication, including the strength you are requesting",
            errorText: "Add at least one medication",
            optionPrefix: "Medication",
        }),
        nextBlockId: null,
    }),
];
