export type OrganisationSettings = {
    success: boolean;
    errorMessage?: string | null;
    organisationId: number;
    organisationCode: string;
    organisationName: string;
    organisationPhoneNumber: string;
    organisationAddress: OrganisationAddress;
    feedbackFormLink: string | null;
    translationLanguages: TranslationLanguage[];
    landingPageSettings: LandingPageSettings;
    confirmationPageSettings: ConfirmationPageSettings;
    submissionPageMessage: string | null;
    requestTrackingId: string;
    organisationAssignees?: OrganisationAssignees;
    organisationFeatures?: OrganisationFeatures;
    landingPageState: LandingPageStates;
    prescriptionSettings: PrescriptionSettings;
    outOfHoursSettings: OutOfHoursSettings;
    isDownForMaintenance: boolean;
};

export type LandingPageSettings = {
    bannerHeader: string;
    bannerBody: string;
    bannerSubtext: string;
    pageTitle: string;
};

export type ConfirmationPageSettings = {
    submissionPageSubtext: string;
};

export interface OrganisationAddress {
    addressOne: string | null;
    addressTwo: string | null;
    town: string | null;
    postCode: string | null;
}

export type TranslationLanguage = {
    name: string;
    code: string;
    nativeName: string;
};

export type OrganisationAssignees = {
    userGroups: OrganisationUserGroupAssignee[];
    users: OrganisationUserAssignee[];
};
type OrganisationUserAssignee = {
    id: number;
    fullName: string;
};
type OrganisationUserGroupAssignee = {
    id: number;
    displayName: string;
};

export type OrganisationFeatures = {
    patientInitiatedNhsLogin: boolean;
    translationsEnabled: boolean;
};

export enum LandingPageStates {
    ENABLED = "Enabled",
    ALL_SUSPENDED = "AllSuspended",
    SUSPENDED_WITH_ADMIN_ONLY_ENABLED = "SuspendedWithAdminOnlyEnabled",
    OUT_OF_HOURS_PATIENT_TRIAGE_ENABLED = "OutOfHoursPatientTriageEnabled",
    OUT_OF_HOURS_WITH_ADMIN_ONLY_ENABLED = "OutOfHoursWithAdminOnlyEnabled",
    OUT_OF_HOURS_PATIENT_TRIAGE_SUSPENDED = "OutOfHoursPatientTriageSuspended",
}

export type PrescriptionSettings = {
    isEnabled: boolean;
    isRedirectEnabled: boolean;
    redirectSettings: PrescriptionRedirectionSettings | null;
};

export type PrescriptionRedirectionSettings = {
    title: string;
    url: string;
    isPrescriptionFormEnabled: boolean;
};

export type OutOfHoursSettings = {
    showOutOfHoursPage: boolean;
};
