import { useRouter } from "next/router";
import { useEffect } from "react";
import { AnswerContextState, useAnswer } from "../context/AnswerContext";
import { getPatientInitiatedRoute } from "../constant/paths";

type SelectedSectionName =
    | "selectedSectionId"
    | "selectedCategoryId"
    | "selectedFlowId";

type RemoveNull<T extends SelectedSectionName> = Exclude<
    AnswerContextState[T],
    null
>;

export type SelectedSections = {
    selectedSectionId: RemoveNull<"selectedSectionId">;
    selectedCategoryId: RemoveNull<"selectedCategoryId">;
    selectedFlowId: RemoveNull<"selectedFlowId">;
};

export const useSelectedSections = (): SelectedSections | null => {
    const { selectedSectionId, selectedCategoryId, selectedFlowId } =
        useAnswer();

    const isSelectionValid =
        selectedSectionId && selectedCategoryId && selectedFlowId;
    const router = useRouter();

    useEffect(() => {
        if (isSelectionValid) return;
        router.push(
            getPatientInitiatedRoute(router.query.organisationCode, ""),
        );
    }, [isSelectionValid, router]);

    if (!isSelectionValid) return null;

    return { selectedSectionId, selectedCategoryId, selectedFlowId };
};
