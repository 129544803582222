import { useOrganisation } from "../../context/OrganisationContext";
import { FormFieldWrapper, Option, Select, Text } from "@accurx/design";
import { Content, useContent } from "../../context/ContentContext";
import { HTMLAttributes, useEffect, useState } from "react";
import { DEFAULT_LANGUAGE } from "../../settings";
import { getLanguageDirection } from "../../util/language.utils";
import { TranslationLanguage } from "../../api/organisation";
import { fetchTranslations } from "../../api/translation";
import { LanguageChangeModal } from "./LanguageChangeModal";
import { trackTriageFormLanguageSelected } from "../../analytics/PatientInitiatedEvents";

type LanguageSelectorProps = {
    props?: HTMLAttributes<HTMLDivElement>;
};

export const LanguageSelector = (props: LanguageSelectorProps): JSX.Element => {
    const { organisationSettings } = useOrganisation();
    const {
        meta,
        selectedLanguage,
        recipientDetailsQuestions,
        verificationQuestion,
        sections,
        landingPageSettingsContent,
        setSelectedLanguage,
        setContentForLanguage,
    } = useContent();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const languages = [
        DEFAULT_LANGUAGE,
        ...(organisationSettings?.translationLanguages ?? []),
    ];

    const trackLanguageChangeEvent = (): void => {
        return trackTriageFormLanguageSelected({
            anonymousRequestId: organisationSettings?.requestTrackingId ?? "",
            userOrganisationId: organisationSettings?.organisationId ?? 0,
            languageSelected: selectedLanguage?.name ?? null,
        });
    };

    const handleChange = async (option: Option | Option[]): Promise<void> => {
        if (Array.isArray(option)) {
            throw new Error("Only one language can be selected");
        }

        if (option.value === selectedLanguage.code) return;

        const language = languages.find(({ code }) => code === option.value);
        if (!language) throw new Error("Cannot find translation language");

        if (language.code === DEFAULT_LANGUAGE.code) {
            setSelectedLanguage(DEFAULT_LANGUAGE);
            return;
        }

        const response = await fetchTranslations<Content>({
            language: language.code,
            siteContent: {
                meta,
                sections,
                recipientDetailsQuestions,
                verificationQuestion,
                landingPageSettingsContent,
            },
            organisationCode: organisationSettings?.organisationCode ?? "",
            requestTrackingId: organisationSettings?.requestTrackingId ?? "",
        });
        if (!response) return;

        setContentForLanguage({
            language: language.code,
            content: response.content,
        });
        trackLanguageChangeEvent();
        setSelectedLanguage(language);
    };

    useEffect(() => {
        if (selectedLanguage.code === DEFAULT_LANGUAGE.code) return;
        setIsModalOpen(true);
    }, [selectedLanguage]);

    if (!organisationSettings?.organisationFeatures?.translationsEnabled) {
        return <></>;
    }

    const getOption = (language: TranslationLanguage): Option => ({
        value: language.code,
        label: language.nativeName,
        labelElement: (
            <Text
                as="span"
                skinny
                props={{
                    lang: language.code,
                    dir: getLanguageDirection(language.code),
                }}
            >
                {language.nativeName}
            </Text>
        ),
    });

    const selectId = "language-selector-id";

    return (
        <>
            <FormFieldWrapper
                label={meta.selectLanguage}
                labelProps={{ htmlFor: selectId }}
                {...props}
            >
                <Select
                    id={selectId}
                    initSelectedValues={getOption(selectedLanguage)}
                    label={meta.selectLanguage}
                    options={languages.map(getOption)}
                    onChangeHandler={handleChange}
                />
            </FormFieldWrapper>
            {isModalOpen && (
                <LanguageChangeModal onClose={() => setIsModalOpen(false)} />
            )}
        </>
    );
};
