type EmergencySignsString = {
    bold: string;
    regular: string;
};

export const adultEmergencySigns: EmergencySignsString[] = [
    {
        bold: "signs of a heart attack",
        regular:
            "chest pain, pressure, heaviness, tightness or squeezing across the chest",
    },
    {
        bold: "signs of a stroke",
        regular:
            "face dropping on one side, cannot hold both arms up, difficulty speaking",
    },
    {
        bold: "sudden confusion (delirium)",
        regular:
            "cannot be sure of own name or age, slurred speech or not making sense",
    },
    {
        bold: "suicide attempt",
        regular: "by taking something or self-harming",
    },
    {
        bold: "severe difficulty breathing",
        regular:
            "not being able to get words out, breathing very fast, choking or gasping",
    },
    {
        bold: "heavy bleeding",
        regular: "spraying, pouring or enough to make a puddle",
    },
    {
        bold: "severe injuries",
        regular: "after a serious accident",
    },
    {
        bold: "seizure (fit)",
        regular:
            "shaking or jerking because of a fit, or unconscious (can't be woken up)",
    },
    {
        bold: "sudden, rapid swelling",
        regular: "of the lips, mouth, throat or tongue",
    },
    {
        bold: "labour or childbirth",
        regular:
            "water breaking, more frequent intense cramps (contractions), baby coming, or just born",
    },
    {
        bold: "signs of a severe infection (sepsis)",
        regular: `blue, grey, pale or blotchy skin, lips, tongue, palms of soles; a rash that does not fade when you roll a glass over it or 
            high temperature with a stiff neck / bothered by light
        `,
    },
];

export const childEmergencySigns: EmergencySignsString[] = [
    {
        bold: "seizure (fit)",
        regular:
            "shaking or jerking because of a fit, or unconscious (cannot be woken up)",
    },
    {
        bold: "choking",
        regular: "on liquids or solids now",
    },
    {
        bold: "difficulty breathing",
        regular:
            "making grunting noises, sucking their stomach in under their ribcage or breathing very fast",
    },
    {
        bold: "unable to stay awake",
        regular:
            "being sleepier than normal or difficult to wake, cannot keep their eyes open for more than a few seconds",
    },
    {
        bold: "blue, grey, pale or blotchy skin, tongue or lips",
        regular:
            "on brown or black skin, grey or blue palms or soles of the feet",
    },
    {
        bold: "limp and floppy",
        regular: "their head falls to the side, backwards or forwards",
    },
    {
        bold: "heavy bleeding",
        regular: "spraying, pouring or enough to make a puddle",
    },
    {
        bold: "severe injuries",
        regular: "after a serious accident or assault",
    },
    {
        bold: "signs of a stroke",
        regular:
            "face dropping on one side, cannot hold both arms up, difficulty speaking",
    },
    {
        bold: "sudden, rapid swelling",
        regular: "of the lips, mouth, throat or tongue",
    },
    {
        bold: "sudden confusion",
        regular: "agitation, odd behaviour or non-stop crying",
    },
    {
        bold: "signs of a severe infection (sepsis)",
        regular: `a rash that does not fade when you roll a glass over it; a weak or high-pitched cry that's not like their normal cry; 
            not responding like they normally do; not interested in feeding or normal activities; or 
            high temperature with a stiff neck / bothered by light
        `,
    },
];

export const emergencySymptoms = { adultEmergencySigns, childEmergencySigns };
