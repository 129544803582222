import { useIdleTimer } from "react-idle-timer";

type idleTimerProps = {
    onIdle: () => any;
    timeout: number;
};

export const useIdleTimerSetup = (props: idleTimerProps) => {
    useIdleTimer({
        onIdle: props.onIdle,
        timeout: props.timeout,
    });
};
