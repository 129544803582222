import styled from "styled-components";
import { Tokens } from "@accurx/design";

export const StyledHeader = styled.div`
    background-color: ${Tokens.COLOURS.greyscale.white};
    width: 100%;
    position: sticky;
    top: 0;
`;

export const StyledTextWrapper = styled.div`
    border-bottom: 1px solid ${Tokens.COLOURS.greyscale.silver};
    background-color: ${Tokens.COLOURS.greyscale.cloud};
    padding: ${Tokens.SIZES[1]} ${Tokens.SIZES[2]};
`;
