import { TranslationLanguage } from "./api/organisation";

export const DEFAULT_LANGUAGE: TranslationLanguage = {
    name: "English",
    nativeName: "English",
    code: "en",
};

export const QUESTION_SETTINGS = {
    FREE_TEXT_CHARACTER_LIMIT: 500,
    ADD_OPTION_DEFAULT: 2,
    ADD_OPTION_LIMIT: 20,
} as const;

export enum Recipient {
    myself = "myself",
    someoneElse = "someoneElse",
    patient = "patient",
}

export const DEFAULT_RECIPIENTS = [Recipient.myself, Recipient.someoneElse];
export const RECEPTION_RECIPIENTS = [Recipient.patient, Recipient.someoneElse];

export const DEFAULT_RECIPIENT = Recipient.myself;
export const DEFAULT_RECEPTION_RECIPIENT = Recipient.patient;

export const CLINICIAN_QUESTION_FLOW_TITLE = "Medical request";
export const CLINICIAN_QUESTION_CONTACT_PREFERENCE = "Phone call";
